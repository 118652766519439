import { Base } from "../base";
import { Japanese } from "flatpickr/dist/l10n/ja.js";
import { csrfToken } from "@rails/ujs";
import { getFileNameFromContentDisposition } from "../common/common.js";

import flatpickr from 'flatpickr';
import Swal from "sweetalert2";

import 'flatpickr/dist/flatpickr.min.css';

export class Admin__OrderRestaurants extends Base {
  /**
   * Action index
   */
  index() {
    document.getElementById('admin-container').addEventListener('scroll', function () {
      for (const calendar of document.getElementsByClassName('flatpickr-calendar')) {
        calendar.classList.remove('open');
      }
    });

    var datepicker = document.querySelectorAll('.datepicker');
    datepicker.forEach((element) => {
      flatpickr(element, {
        dateFormat: 'Y/m/d',
        locale: Japanese,
        defaultDate: element.value,
      });
      element.addEventListener('change', function (e) {
        element.value = dayjs(e.target.value).format('YYYY/MM/DD');
      });
      element.addEventListener('keydown', function (e) {
        if (e.key === 'Backspace' || e.key === 'Delete') {
          e.target.value = '';
        }
      });
    });

    var datetimepicker = document.querySelectorAll('.datetimepicker');
    datetimepicker.forEach((element) => {
      flatpickr(element, {
        enableTime: true,
        dateFormat: 'Y/m/d H:i',
        locale: Japanese,
        defaultDate: element.value,
      });
      element.addEventListener('change', function (e) {
        element.value = dayjs(e.target.value).format('YYYY/MM/DD HH:mm');
      });
      element.addEventListener('keydown', function (e) {
        if (e.key === 'Backspace' || e.key === 'Delete') {
          e.target.value = '';
        }
      });
    });
  }
  show() {
    console.log("🚀 ~ Run code javascript on Admin__Orders show");
    this.initButtonCancelOrder();
    this.initButtonInvoice();
    this.initButtonDeletePlan();
    this.initButtonChangePlan();
    this.initButtonSaveChangePlan();
  }

  // プランを削除する
  initButtonDeletePlan() {
    $(".btn-delete-plan").click(async function () {
      const order_plans = document.querySelectorAll('.order-plan__info')
      const result = await Swal.fire({
        title: "確認",
        text: order_plans?.length <= 1 ? "この予約をキャンセルしてもよろしいですか。" : "このプランを削除してもよろしいですか。",
        icon: "error",
        showCancelButton: true,
        confirmButtonText: order_plans?.length <= 1 ? 'OK' : "削除",
        cancelButtonText: "キャンセル",
      });
      if (result.isConfirmed) {
        if (order_plans?.length <= 1) {
          const orderId = document.querySelector(".order-id").value;
          const response = await fetch(`/admin/order_dogruns/cancel_order`, {
            method: "POST",
            body: JSON.stringify({ id: orderId }),
            headers: {
              "Content-Type": "application/json",
              "X-CSRF-Token": csrfToken(),
            },
          });
          const data = await response.json();
          if (data.success) {
            const result = await Swal.fire({
              title: "成功",
              text: "予約ーをキャンセルしました",
              icon: "success",
            });
            if (result.isConfirmed) {
              window.location.reload(true);
            }
          } else {
            Swal.fire({
              title: "エラー",
              text: "オーダーのキャンセルに失敗しました",
              icon: "error",
            });
          }
        }
        else {
          const orderPlanId = $(this).find(".order-plan-id").val();
          const response = await fetch(`/admin/order_dogruns/delete_plan`, {
            method: "POST",
            body: JSON.stringify({ id: orderPlanId }),
            headers: {
              "Content-Type": "application/json",
              "X-CSRF-Token": csrfToken(),
            },
          });
          const data = await response.json();
          if (data.success) {
            const result = await Swal.fire({
              title: "成功",
              text: "プランを削除しました",
              icon: "success",
            });
            if (result.isConfirmed) {
              window.location.reload(true);
            }
          } else {
            Swal.fire({
              title: "エラー",
              text: "プランの削除に失敗しました",
              icon: "error",
            });
          }
        }
      }
    });
  }

  // プランを変更する
  initButtonChangePlan() {
    var restaurants = [];
    var selectedRestaurantId = 0;
    var selectedPlanID = 0;
    var peopleCount = 0;
    var child11Count = 0;
    var child6Count = 0;
    var child3Count = 0;
    const truncateString = (str, num) => {
      return str.length > num ? str.slice(0, num) + '...' : str;
    }
    $(".btn-change-plan").click(async function () {
      selectedRestaurantId = $(this).find(".restaurant-id").val();
      selectedPlanID = $(this).find(".plan-id").val();
      const timeStart = $(this).find(".time-start").val();
      const adultCount = $(this).find(".adult-count").val();
      child11Count = parseInt(jQuery(this).find(".children_under_11_count").val());
      child6Count = parseInt(jQuery(this).find(".children_under_6_count").val());
      child3Count = parseInt(jQuery(this).find(".children_under_3_count").val());
      $('.old_order_plan_id').val($(this).find(".order-plan-id").val());
      $('.new_plan_id').val(0);
      $('.people_count').val(Number(adultCount) + Number(child11Count) + Number(child6Count));
      $('.time_start').val(timeStart);
      $(".modal__plan__info").html($(this).closest(".order-plan__info").find(".plan__info").html());
      const response = await fetch(`/admin/order_dogruns/get_list_plan`, {
        method: "POST",
        body: JSON.stringify({ time_start: timeStart, adult_count: adultCount, children_under_11_count: child11Count, children_under_6_count: child6Count, children_under_3_count: child3Count, selected_plan: selectedPlanID }),
        headers: {
          "Content-Type": "application/json",
          "X-CSRF-Token": csrfToken(),
        },
      });
      const data = await response.json();
      if (data.success) {
        if (data.restaurants.length > 0) {
          let html = '';
          let html_plans = '';
          restaurants = data.restaurants;
          peopleCount = data.people_count
          restaurants.forEach(function (restaurant) {
            const shortTitle = restaurant.title.length > 50 ? restaurant.title.substring(0, 50) + '...' : restaurant.title;

            html += `<option value="${restaurant.restaurant_id}" ${selectedRestaurantId == restaurant.restaurant_id ? 'selected' : ''} title="${restaurant.title}">${shortTitle}</option>`;
          });
          $('.room__select select').html(html);

          restaurants.forEach(function (restaurant) {
            if (restaurant.restaurant_id == $('.room__select select').val()) {
              restaurant.plans.forEach(function (plan) {
                let price = 0;
                const shortName = truncateString(plan.plan_name, 25);
                const shortDescription = truncateString(plan.description, 50);

                // Kiểm tra cả selectedPlanID và giá trị của .new_plan_id
                let isChecked = (selectedPlanID == plan.id || $('.new_plan_id').val() == plan.id) ? 'checked' : '';

                if (plan.price != null) price = plan.price;
                html_plans += `
                <div class="col-6 pt-2">
                  <div class="radio-button">
                    <input type="radio" class="plan-check" name="plan" value="${plan.id}" ${isChecked}>
                  </div>
                  <div class="plan-info-area">
                    <p class="font-weight-semibold2" title="${plan.plan_name}">${shortName}</p>
                    <p class="modal-plan-description" title="${plan.description}">${shortDescription}</p>
                    <p class="text-muted">合計：<span class='font-weight-semibold2'>￥${((Number(price) + Number(restaurant.price_default)) * Number(peopleCount)).toLocaleString('en-US')}</span></p>
                  </div>
                </div>`;
              });
            }
          });
          $('.modal_checkbox_group').html(html_plans);
        } else {
          $('.modal_checkbox_group').html("<p>データが見つかりませんでした</p>");
        }
        $("#change_plan_modal").show();
      } else {
        Swal.fire({
          title: "エラー",
          text: "プランの変更に失敗しました",
          icon: "error",
        });
      }
      const radios = document.querySelectorAll('.plan-check');
      radios.forEach(function (radio) {
        radio.addEventListener('change', function () {
          $('.new_plan_id').val(this.value);
          selectedPlanID = parseInt(this.value); // Cập nhật selectedPlanID
        });
      });
    });

    this.initButtonCloseModal();

    $('.room__select select').change(function () {
      selectedRestaurantId = $(this).val();
      let html_plans = '';
      restaurants.forEach(function (restaurant) {
        if (restaurant.restaurant_id.toString() == selectedRestaurantId.toString()) {
          restaurant.plans.forEach(function (plan) {
            let price = 0;
            if (plan.price != null) price = plan.price;
            
            // Kiểm tra cả selectedPlanID và giá trị của .new_plan_id
            let isChecked = (selectedPlanID == plan.id || $('.new_plan_id').val() == plan.id) ? 'checked' : '';
            
            html_plans += `<div class="col-6 pt-2">
              <div class="radio-button">
                <input type="radio" class="plan-check" name="plan" value="${plan.id}" ${isChecked}>
              </div>
              <div class="plan-info-area">
                <p class="font-weight-semibold2">${truncateString(plan.plan_name, 25)}</p>
                <p class="modal-plan-description">${truncateString(plan.description, 50)}</p>
                <p class="text-muted">合計：<span class='font-weight-semibold2'>￥${((Number(price) + Number(restaurant.price_default)) * Number(peopleCount)).toLocaleString('en-US')}</span></p>
              </div>
            </div>`;
          });
        }
      });
      $('.modal_checkbox_group').html(html_plans);
      const radios = document.querySelectorAll('.plan-check');
      radios.forEach(function (radio) {
        radio.addEventListener('change', function () {
          $('.new_plan_id').val(this.value);
          selectedPlanID = parseInt(this.value); // Cập nhật selectedPlanID
        });
      });
    });
  }

  initButtonSaveChangePlan() {
    $(".btn-save-change-plan").click(async function () {
      const result = await Swal.fire({
        title: "確認",
        text: "このプランを変更してもよろしいですか",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "編集",
        cancelButtonText: "キャンセル",
      });

      if (result.isConfirmed) {
        const newPlanId = $(this).find(".new_plan_id").val();
        const timeStart = $(this).find(".time_start").val();
        const peopleCount = $('.people_count').val();
        const oldOderPlanId = $(this).find(".old_order_plan_id").val();
        $(".modal__plan__info").html($(this).closest(".order-plan__info").find(".plan__info").html());
        const response = await fetch(`/admin/order_dogruns/change_plan`, {
          method: "POST",
          body: JSON.stringify({ old_order_plan_id: oldOderPlanId, new_plan_id: newPlanId, time_start: timeStart, people_count: peopleCount }),
          headers: {
            "Content-Type": "application/json",
            "X-CSRF-Token": csrfToken(),
          },
        });
        const data = await response.json();
        if (data.success) {
          const result = await Swal.fire({
            title: "成功",
            text: "プランを編集しました。",
            icon: "success",
          });
          if (result.isConfirmed) {
            window.location.reload(true);
          }
        } else {
          if (data.message == "room_not_available") {
            const resultError = await Swal.fire({
              title: "エラー",
              text: "ご指定いただいたドッグランは満でございます。別のドッグランをお選びください。",
              icon: "error",
            });
            if (resultError.isConfirmed) {
              window.location.reload(true);
            }
          } else {
            Swal.fire({
              title: "エラー",
              text: "プランの変更に失敗しました",
              icon: "error",
            });
          }
        }
      }
    });
  }

  initButtonCloseModal() {
    $(".modal__close").click(async function () {
      const selectedPlanID = $('.new_plan_id').val();
      if (selectedPlanID > 0) {

        const result = await Swal.fire({
          title: "確認",
          text: "変更がありますが、このまま閉じてもよろしいですか。",
          icon: "error",
          showCancelButton: true,
          confirmButtonText: "OK",
          cancelButtonText: "キャンセル",
        });
        if (!result.isConfirmed) {
          return;
        }
      }
      $("#change_plan_modal").hide();
    });
  }

  // キャンセルボタン
  initButtonCancelOrder() {
    const btn = document.querySelector(".btn-cancel-order");
    if (!btn) return;
    btn.addEventListener("click", async function () {
      const result = await Swal.fire({
        title: "確認",
        text: "この予約をキャンセルしてもよろしいですか。",
        icon: "error",
        showCancelButton: true,
        confirmButtonText: "OK",
        cancelButtonText: "キャンセル",
      });
      if (result.isConfirmed) {
        const orderId = document.querySelector(".order-id").value;
        const response = await fetch(`/admin/order_dogruns/cancel_order`, {
          method: "POST",
          body: JSON.stringify({ id: orderId }),
          headers: {
            "Content-Type": "application/json",
            "X-CSRF-Token": csrfToken(),
          },
        });
        const data = await response.json();
        if (data.success) {
          const result = await Swal.fire({
            title: "成功",
            text: "予約ーをキャンセルしました",
            icon: "success",
          });
          if (result.isConfirmed) {
            jQuery('div.loading-modal').show();
            window.location.reload(true);
          }
        } else {
          Swal.fire({
            title: "エラー",
            text: "オーダーのキャンセルに失敗しました",
            icon: "error",
          });
        }
      }
    });
  }

  initButtonInvoice() {
    const btn = document.querySelector(".btn-invoice");
    if (!btn) return;
    btn.addEventListener("click", async function () {
      const result = await Swal.fire({
        title: "確認",
        text: "この予約に対して請求書を発行しますか",
        icon: "info",
        showCancelButton: true,
        confirmButtonText: "OK",
        cancelButtonText: "閉じる",
      });
      if (result.isConfirmed) {
        const orderId = document.querySelector(".order-id").value;
        const response = await fetch(`/admin/order_dogruns/download_pdf`, {
          method: "POST",
          body: JSON.stringify({ id: orderId }),
          headers: {
            "Content-Type": "application/json",
            "X-CSRF-Token": csrfToken(),
          },
        });
        const blob = await response.blob();
        const contentDisposition = response.headers.get("Content-Disposition");
        const filename = getFileNameFromContentDisposition(contentDisposition);
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        a.download = filename;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        window.URL.revokeObjectURL(url);
      }
    });
  }
}
