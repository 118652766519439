import { Base } from "../base";
import Swal from "sweetalert2";
import { csrfToken } from "@rails/ujs";
import { Japanese } from "flatpickr/dist/l10n/ja.js";

import flatpickr from 'flatpickr';

import 'flatpickr/dist/flatpickr.min.css';

export class Admin__Coupons extends Base {
  index() {
    var buttonDestroys = document.querySelectorAll(".button-destroy-coupon");
    buttonDestroys.forEach(function (button) {
      button.addEventListener("click", async function (e) {
        const result = await Swal.fire({
          title: "確認",
          text: "このクーポンを削除してもよろしいですか。",
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: "削除",
          cancelButtonText: "キャンセル",
        });
        if (result.isConfirmed) {
          const couponId = this.value;
          const response = await fetch(`/admin/coupons/${couponId}`, {
            method: "DELETE",
            body: JSON.stringify({ id: couponId }),
            headers: {
              "Content-Type": "application/json",
              "X-CSRF-Token": csrfToken(),
            },
          });
          const data = await response.json();
          if (data.success) {
            const result = await Swal.fire({
              title: "成功",
              text: "クーポンは削除されました",
              icon: "success",
            });
            if (result.isConfirmed) {
              window.location.reload(true);
            }
          } else {
            Swal.fire({
              title: "エラー",
              text: "クーポンの削除に失敗しました",
              icon: "error",
            });
          }
        }
      });
    });
  }

  create() {
    this.common();
  }

  new() {
    this.common();
  }

  edit() {
    this.common();
  }

  common() {
    $(function () {
      $('#coupon_discount').on('input', function () {
        var value = $(this).val();
        if (value < 1) {
          if (value === null || value === undefined || value === '') {
            value = '';
          } else {
            value = 1;
          }
        }
        if (value > 100) {
          value = 100;
        }
        $(this).val(value);
      });
    });

    $('#coupon_quantity').on('input', function () {
      var value = $(this).val();
      if (value < 1) {
        if (value === null || value === undefined || value === '') {
          value = '';
        } else {
          value = 1;
        }
      }
      $(this).val(value);
    });

    if (!document.getElementById('admin-container')) return;

    document.getElementById('admin-container').addEventListener('scroll', function () {
      for (const calendar of document.getElementsByClassName('flatpickr-calendar')) {
        calendar.classList.remove('open');
      }
    });

    flatpickr('#coupon_from_date', {
      dateFormat: 'Y/m/d',
      locale: Japanese,
      minDate: 'today',
    });
    document.querySelector('#coupon_from_date').addEventListener('keydown', function (e) {
      if (e.key === 'Backspace' || e.key === 'Delete') {
        e.target.value = dayjs(new Date()).format('YYYY/MM/DD');
      }
    });

    flatpickr('#coupon_to_date', {
      dateFormat: 'Y/m/d',
      locale: Japanese,
      minDate: 'today',
    });
    document.querySelector('#coupon_to_date').addEventListener('keydown', function (e) {
      if (e.key === 'Backspace' || e.key === 'Delete') {
        e.target.value = dayjs(new Date()).format('YYYY/MM/DD');
      }
    });

    // format input Number
    var numberInputs = document.querySelectorAll('.number-input');
    numberInputs.forEach((input) => {
      input.addEventListener('sl-input', (event) => {
        let value = event.target.value === null || event.target.value === undefined || event.target.value === '' ? '' : event.target.value.replace(/\D/g, ''); // Loại bỏ tất cả ký tự không phải số
        event.target.value = value;
      });
    });
  }
}
