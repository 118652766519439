import { Base } from "../base";

export class Admin__Restaurants extends Base {
  create() {
    this.common();
  }

  new() {
    this.common();
  }

  edit() {
    this.common();
  }

  common() {
    if (document.querySelector('body').getAttribute('data-action') === 'index') return;

    // format input Number
    var numberInputs = document.querySelectorAll('.number-input');
    numberInputs.forEach((input) => {
      input.addEventListener('sl-input', (event) => {
        let value = event.target.value === null || event.target.value === undefined || event.target.value === '' ? '' : event.target.value.replace(/\D/g, ''); // Loại bỏ tất cả ký tự không phải số
        value = value.length >= 7 ? value.slice(0, 7) : value;
        var formatted = value === '' ? '' : new Intl.NumberFormat('ja-JP', {
          style: 'decimal',
        }).format(value);
        event.target.value = formatted;
      });
    });

    // Upload images
    const fileImages = document.getElementById("file-images");
    fileImages.onchange = async (event) => {
      var submitBtn = document.getElementById('room-submit-btn');
      var parentDiv = document.querySelector('.form__card.upload__card');
      var spinnerDiv = document.createElement('div');

      spinnerDiv.className = 'upload__card_uploading';
      spinnerDiv.innerHTML = '<sl-spinner></sl-spinner>';
      parentDiv.appendChild(spinnerDiv);

      document.querySelectorAll('.upload__delete').forEach(function (element) {
        element.style.display = 'none';
      });

      submitBtn.setAttribute('loading', '');
      submitBtn.disabled = true;

      for (const file of event.currentTarget.files) {
        // Upload image
        let formData = new FormData();
        formData.append('file', file);
        let response = await fetch('/admin/files', {
          method: 'POST',
          body: formData
        });
        let blob = JSON.parse(await response.text());
        let blobIdInput = document.getElementById('blob-ids');

        if (blobIdInput.value) {
          blobIdInput.value += ',' + blob.blob_id;
        } else {
          blobIdInput.value = blob.blob_id;
        }

        var imageUrl = URL.createObjectURL(file);
        var imageDiv = document.getElementById('image__preview');
        var newDiv = document.createElement('div');
        newDiv.classList.add('upload__image--item');
        newDiv.innerHTML = `
        <img
          src="${imageUrl}"
          width="100%"
          height="100%"
        />
        <sl-tooltip content="消す">
          <div
            class="upload__delete"
            data="${blob.blob_id}"
            onclick="
              this.parentNode.parentNode.remove();
              const blobIds = document.getElementById('blob-ids').value.split(',');
              const index = blobIds.indexOf(this.getAttribute('data'));
              if (index > -1) {
                blobIds.splice(index, 1);
                document.getElementById('blob-ids').value = blobIds;
              }"
          ><img src="/static-assets/icons/close.svg" alt="" />
          </div>
        </sl-tooltip>`;
        imageDiv.appendChild(newDiv);
      }
      document.querySelectorAll('.upload__delete').forEach(function (element) {
        element.style.display = 'flex';
      });
      spinnerDiv.remove();
      submitBtn.removeAttribute('loading');
      submitBtn.disabled = false;
      event.target.value = '';
    }

    // Add プラン設定
    const btnAddPlan = document.getElementById("btn__add_plan");
    btnAddPlan.onclick = function () {
      var timestamp = new Date().getTime();
      var newDiv = document.createElement('div');
      newDiv.innerHTML = `
      <div class="form__card plan_item" style="margin-bottom: 15px">
        <div class="form__control">
          <div class="form__control--label">
            プラン名 <span class="required">*</span>
          </div>
          <sl-input class="form__control--input" name="restaurant[restaurant_plans_attributes][${timestamp}][plan_name]" placeholder="プラン名"></sl-input>
        </div>
        <div class="form__control">
          <div class="form__control--label">
            説明 <span class="required">*</span>
          </div>
          <sl-textarea name="restaurant[restaurant_plans_attributes][${timestamp}][description]" placeholder="説明"></sl-textarea>
        </div>
        <div class="form__control">
          <div class="form__control--label">金額<span class="required">*</span></div>
          <sl-input class="form__control--input number-input" maxlength="9" name="restaurant[restaurant_plans_attributes][${timestamp}][price]">
            <sl-icon name="currency-yen" slot="prefix"  placeholder="金額"></sl-icon>
          </sl-input>
        </div>

        <div class="form__card--delete">
          <a style="color: #0d6efd; cursor: pointer" onclick="this.parentNode.parentNode.remove()">削除</a>
        </div>
      </div>`;
      var plans = document.getElementsByClassName("plan_item");
      var lastPlan = plans[plans.length - 1];
      lastPlan.parentNode.insertBefore(newDiv, lastPlan.nextSibling);

      var numberInput = newDiv.querySelector('.number-input');

      numberInput.addEventListener('sl-input', function (event) {
        let value = event.target.value === null || event.target.value === undefined || event.target.value === '' ? '' : event.target.value.replace(/\D/g, ''); // Loại bỏ tất cả ký tự không phải số
        value = value.length >= 7 ? value.slice(0, 7) : value;
        var formatted = value === '' ? '' : new Intl.NumberFormat('ja-JP', {
          style: 'decimal',
        }).format(value);
        event.target.value = formatted;
      });
    }
  }
}
