import { Base } from "../base";
export class Admin__Dashboard extends Base {
  // /**
  //  * Action index
  //  */
  index() {
    // click order type (rooms or restaurant or campsite)
    jQuery('.order-type').on('click', async function() {
      jQuery('input[name=order_type]').val(jQuery(this).find('input').val());
      // change backgroundcolor of value
      jQuery('.order-type').removeClass('selected');
      jQuery(this).addClass('selected');
      // get data to append for table
      let selected_month = jQuery('#_selected_month_1i').val() + '-' + jQuery('#_selected_month_2i').val() + '-01';
      let selected_order_type = jQuery('input[name=order_type]').val();
      let queryStr = 'order_type=' + selected_order_type + '&search_date=' + selected_month;
      await fetch(`/admin/dashboard/get_orders_for_dashboard?` + queryStr, {
        method: "GET",
        headers: {
          Accept: 'text/html',
          'Content-Type': 'text/html;charset=UTF-8'
        },
        credentials: 'same-origin'
      }).then(function(response) {
        return response.text();
      }).then(function (html) {
        jQuery('table.table__rooms').html(html);
      });

    });
  }
}
