// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from "@rails/ujs";
import Turbolinks from "turbolinks";
import * as ActiveStorage from "@rails/activestorage";
import "../lib/app.js";
const images = require.context("../images", true);

Rails.start();
Turbolinks.start();
ActiveStorage.start();

require("trix");
require("@rails/actiontext");

// Common
import "../javascripts/lib/left_menu.js";
import "../javascripts/lib/datepicker.js";
import "../javascripts/lib/datetimepicker.js";
// app/webpack/lib/js/modules/plugins/toggle-sidebar/sidemenu.js
// import "../lib/js/modules/plugins/toggle-sidebar/sidemenu.js";

// Controllers
import * as controllers from "../javascripts/controllers";

document.addEventListener("turbolinks:load", function () {

  //ページを読み込んだ時にwindow.Appにコントローラー毎のオブジェクトを代入する
  const body = document.querySelector("body");
  if (body) {
    const data = body.dataset;
    if (data) {
      const controllerName = data.controller;
      const actionName = data.action;
      if (
        controllerName &&
        actionName &&
        typeof controllers[controllerName] === "function"
      ) {
        window.App = new controllers[controllerName](actionName);
      }
    }
  }
});
