import { Base } from "../base";
import Swal from "sweetalert2";
import { Japanese } from "flatpickr/dist/l10n/ja.js";
import flatpickr from 'flatpickr';
import { csrfToken } from "@rails/ujs";
import { getFileNameFromContentDisposition } from "../common/common.js";

export class Admin__Orders extends Base {
  /**
   * Action index
   */
  index() {
    // Datepicker
    console.log("🚀 ~ Run code javascript on Admin__Orders index");
    var datepicker = document.querySelectorAll('.datepicker');
    datepicker.forEach((element) => {
      flatpickr(element, {
        dateFormat: 'Y/m/d',
        locale: Japanese,
        defaultDate: element.value,
      });
      element.addEventListener('change', function (e) {
        element.value = dayjs(e.target.value).format('YYYY/MM/DD');
      });
      element.addEventListener('keydown', function (e) {
        if (e.key === 'Backspace' || e.key === 'Delete') {
          e.target.value = '';
        }
      });
    });
  }
  show() {
    console.log("🚀 ~ Run code javascript on Admin__Orders show");
    this.initButtonCancelOrder();
    this.initButtonInvoice();
    this.initButtonDeletePlan();
    this.initButtonChangePlan();
  }

  // プランを削除する
  initButtonDeletePlan() {
    jQuery(".btn-delete-plan").on('click', async function () {
      let alert_text = '';
      let response_text = '';
      let error_response_text = '';
      if (parseInt(jQuery('#order_plans_count').val()) > 1) {
        alert_text = "このプランを削除してもよろしいですか。";
        response_text = "プランを削除しました。";
        error_response_text = "プランの削除に失敗しました。";
      } else {
        alert_text = "この予約をキャンセルしてもよろしいですか。";
        response_text = "予約をキャンセルしました。";
        error_response_text = "予約のキャンセルに失敗しました。";
      }
      const result = await Swal.fire({
        title: "確認",
        text: alert_text,
        icon: "error",
        showCancelButton: true,
        confirmButtonText: "OK",
        cancelButtonText: "キャンセル",
      });
      if (result.isConfirmed) {
        const orderPlanId = jQuery(this).find(".order-plan-id").val();
        const orderId = document.querySelector(".order-id").value;
        console.log(orderPlanId);
        const response = await fetch('/admin/orders/delete_plan', {
          method: "POST",
          body: JSON.stringify({ id: orderId, order_plan_id: orderPlanId }),
          headers: {
            "Content-Type": "application/json",
            "X-CSRF-Token": csrfToken(),
          },
        });
        const data = await response.json();
        console.log(data);
        // 削除に成功した場合
        if (data.success) {
          const result = await Swal.fire({
            title: "成功",
            text: response_text,
            icon: "success",
          });
          if (result.isConfirmed) {
            window.location.reload(true);
          }
        } else {
          // 削除に失敗した場合
          Swal.fire({
            title: "エラー",
            text: error_response_text,
            icon: "error",
          });
        }
      }
    });
  }

  // プランを変更する
  initButtonChangePlan() {
    var rooms = [];
    var selectedRoomID = 0;
    var selectedPlanID = 0;
    var additional_order_plan_price = 0;
    var adultCount = 0;
    var child11Count = 0;
    var petCount = 0;
    var stay_days = 1;
    jQuery(".btn-change-plan").on('click', async function () {
      const checkinDate = jQuery(this).find(".checkin-date").val();
      const checkoutDate = jQuery(this).find(".checkout-date").val();
      adultCount = parseInt(jQuery(this).find(".adult-count").val());
      child11Count = parseInt(jQuery(this).find(".children_under_11_count").val());
      petCount = parseInt(jQuery(this).find(".pet_count").val());
      const selectedOrderPlanId = jQuery(this).find(".order-plan-id-edit").val();
      selectedRoomID = parseInt(jQuery(this).find(".hidden-room-id").val());
      selectedPlanID = parseInt(jQuery(this).find(".hidden-plan-id").val());
      jQuery('#selected_order_plan_id').val(selectedOrderPlanId);
      jQuery(".modal__plan__info").html(jQuery(this).closest(".order-plan__info").find(".plan__info").html());
      let defaultPriceQueryStr = `order_plan_id=${selectedOrderPlanId}`;
      let listPlanQueryStr = `checkin_date=${checkinDate}&checkout_date=${checkoutDate}&adult_count=${adultCount}&children_under_11_count=${child11Count}&pet_count=${pet_count}&selected_room_id=${selectedRoomID}`;

      // get default price
      const defaultPriceResponse = await fetch(`/admin/orders/get_default_price_for_order_plan?` + defaultPriceQueryStr, {
        method: "GET",
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json'
        },
        credentials: 'same-origin'
      });
      const result = await defaultPriceResponse.json();
      additional_order_plan_price = result.additional_price;
      stay_days = result.stay_days;
      // get list plan
      const response = await fetch(`/admin/orders/get_list_plan?` + listPlanQueryStr, {
        method: "GET",
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json'
        },
        credentials: 'same-origin'
      });
      const data = await response.json();
      if (data.success) {
        if (data.rooms.length > 0) {
          let html = '';
          let html_plans = '';
          rooms = data.rooms;
          rooms.forEach(function(room) {
            const shortTitle = room.title.length > 48 ? room.title.substring(0, 46) + '...' : room.title;
            let isSelected = selectedRoomID == room.room_id ? 'selected' : '';
            html += `<option value="${room.room_id}" title="${room.title}" ${isSelected}>${shortTitle}</option>`;
          });
          jQuery('.room__select select').html(html);

          rooms.forEach(function(room) {
            if (room.room_id == jQuery('.room__select select').val()) {
              room.plans.forEach(function(plan) {
                let price = 0;
                const shortName = plan.plan_name.length > 25 ? plan.plan_name.substring(0, 25) + '...' : plan.plan_name;
                const shortDescription = plan.description.length > 45 ? plan.description.substring(0, 43) + '...' : plan.description;
                let isChecked = (selectedPlanID == plan.id || jQuery('#selected_plan_id').val() == plan.id) ? 'checked' : '';
                if (plan.price != null) {
                  price = plan.price * stay_days + additional_order_plan_price;
                }
                html_plans += `<div class="col-12 col-md-6 pt-2">
                  <div class="radio-button">
                    <input type='radio' class="plan-check" name="plan" value="${plan.id}" ${isChecked}>
                    <input type="hidden" class="plan-check" name="plan_price" value="${price}">
                  </div>
                  <div class="plan-info-area">
                    <p class="font-weight-semibold2" title="${plan.plan_name}">${shortName}</p>
                    <p class="modal-plan-description" title="${plan.description}">${shortDescription}</p>
                    <p class="text-muted">合計：<span class='font-weight-semibold2'>￥${(price).toLocaleString('en-US')}</span></p>
                  </div>
                </div>`;
              });
            }
          });
          jQuery('.modal_checkbox_group').html(html_plans);

          jQuery('input[type=radio]').on('click',  function() {
            jQuery('#selected_plan_id').val(jQuery(this).val());
            jQuery('#selected_price').val(jQuery(this).next('input[name="plan_price"]').val());
            selectedPlanID = parseInt(jQuery(this).val()); // Cập nhật selectedPlanID
          });
        } else {
          jQuery('.modal_checkbox_group').html("<p>データが見つかりませんでした</p>");
        }
        jQuery("#change_plan_modal").show();
      } else {
        Swal.fire({
          title: "エラー",
          text: "プランの変更に失敗しました",
          icon: "error",
        });
      }
    });
    this.initButtonCloseModal();

    jQuery('.room__select select').change(function() {
      selectedRoomID = jQuery(this).val();
      let html_plans = '';
      rooms.forEach(function(room) {
        if (room.room_id == selectedRoomID) {
          room.plans.forEach(function(plan) {
            let price = 0;
            const shortName = plan.plan_name.length > 25 ? plan.plan_name.substring(0, 25) + '...' : plan.plan_name;
            const shortDescription = plan.description.length > 45 ? plan.description.substring(0, 43) + '...' : plan.description;
            let isChecked = (selectedPlanID == plan.id || jQuery('#selected_plan_id').val() == plan.id) ? 'checked' : '';
            if (plan.price != null) price = plan.price * stay_days + additional_order_plan_price;
            html_plans += `<div class="col-12 col-md-6 pt-2">
              <div class="radio-button">
                <input type='radio' class="plan-check" name="plan" value="${plan.id}" ${isChecked}>
                <input type="hidden" class="plan-check" name="plan_price" value="${price}">
              </div>
              <div class="plan-info-area">
                <p class="font-weight-semibold2" title="${plan.plan_name}">${shortName}</p>
                <p class="modal-plan-description" title="${plan.description}">${shortDescription}</p>
                <p class="text-muted">合計：<span class='font-weight-semibold2'>￥${(price).toLocaleString('en-US')}</span></p>
              </div>
            </div>`;
          });
        }
      });
      jQuery('.modal_checkbox_group').html(html_plans);

      jQuery('input[type=radio]').on('click',  function() {
        jQuery('#selected_plan_id').val(jQuery(this).val());
        jQuery('#selected_price').val(jQuery(this).next('input[name="plan_price"]').val());
        selectedPlanID = parseInt(jQuery(this).val()); // Cập nhật selectedPlanID
      });
    });

    jQuery('#change_plan').on('click', async function() {
      const result = await Swal.fire({
        title: "確認",
        text: "このプランに変更してもよろしいですか",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "OK",
        cancelButtonText: "キャンセル",
      });
      if (result.isConfirmed) {
        console.log("change plan");
        const orderId = document.querySelector(".order-id").value;
        // プランを変更する
        const response = await fetch(`/admin/orders/change_plan`, {
          method: "POST",
          body: JSON.stringify({
            id: orderId,
            plan_id:  jQuery('#selected_plan_id').val(),
            order_plan_id: jQuery('#selected_order_plan_id').val(),
            order_plan_price: jQuery('#selected_price').val()
          }),
          headers: {
            "Content-Type": "application/json",
            "X-CSRF-Token": csrfToken(),
          },
        });
        const data = await response.json();
        // プランに成功した場合
        if (data.success) {
          const result = await Swal.fire({
            title: "成功",
            text: "プランを編集しました。",
            icon: "success",
          });
          if (result.isConfirmed) {
            window.location.reload(true);
          }
        } else {
          // プランに失敗した場合
          Swal.fire({
            title: "エラー",
            text: "プランの変更に失敗しました",
            icon: "error",
          });
        }
      }
    });

  }

  initButtonCloseModal() {

    jQuery(".modal__close").on('click', async function () {
      if (jQuery('#selected_plan_id').val() != undefined && jQuery('#selected_plan_id').val() > 0) {

        const result = await Swal.fire({
          title: "確認",
          text: "変更がありますが、このまま閉じてもよろしいですか。",
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: "OK",
          cancelButtonText: "キャンセル",
        });
        if (result.isConfirmed) {
          jQuery('#selected_plan_id').val(0);
          jQuery("#change_plan_modal").hide();
        }
      } else {
        jQuery("#change_plan_modal").hide();
      }
    });
  }
  // キャンセルボタン
  initButtonCancelOrder() {
    const btn = document.querySelector(".btn-cancel-order");
    if (!btn) return;
    btn.addEventListener("click", async function () {
      // キャンセルするか確認する
      const result = await Swal.fire({
        title: "確認",
        text: "この予約をキャンセルしてもよろしいですか",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "OK",
        cancelButtonText: "キャンセル",
      });
      if (result.isConfirmed) {
        console.log("Send mail");
        const orderId = document.querySelector(".order-id").value;
        // オーダーをキャンセルする
        const response = await fetch(`/admin/orders/cancel_order`, {
          method: "POST",
          body: JSON.stringify({ id: orderId }),
          headers: {
            "Content-Type": "application/json",
            "X-CSRF-Token": csrfToken(),
          },
        });
        const data = await response.json();
        // キャンセルに成功した場合
        if (data.success) {
          const result = await Swal.fire({
            title: "成功",
            text: "予約をキャンセルしました。",
            icon: "success",
          });
          if (result.isConfirmed) {
            jQuery('div.loading-modal').show();
            window.location.reload(true);
          }
        } else {
          // キャンセルに失敗した場合
          Swal.fire({
            title: "エラー",
            text: "予約のキャンセルに失敗しました。",
            icon: "error",
          });
        }
      }
    });
  }

  initButtonInvoice() {
    const btn = document.querySelector(".btn-invoice");
    if (!btn) return;
    btn.addEventListener("click", async function () {
      const result = await Swal.fire({
        title: "確認",
        text: "この予約に対して請求書を発行しますか",
        icon: "info",
        showCancelButton: true,
        confirmButtonText: "OK",
        cancelButtonText: "キャンセル",
      });
      if (result.isConfirmed) {
        const orderId = document.querySelector(".order-id").value;
        const response = await fetch(`/admin/orders/download_pdf`, {
          method: "POST",
          body: JSON.stringify({ id: orderId }),
          headers: {
            "Content-Type": "application/json",
            "X-CSRF-Token": csrfToken(),
          },
        });
        const blob = await response.blob();
        const contentDisposition = response.headers.get("Content-Disposition");
        const filename = getFileNameFromContentDisposition(contentDisposition);
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        a.download = filename;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        window.URL.revokeObjectURL(url);
      }
    });
  }
}
