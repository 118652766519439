import $ from 'jquery';
import 'jquery-datetimepicker';
import 'jquery-datetimepicker/jquery.datetimepicker.css';

document.addEventListener("turbolinks:load", function () {
  document.querySelectorAll(".hasDateTimepicker").forEach((el) => el.classList.remove("hasDateTimepicker"));

  $(".fc-datetimepicker").datetimepicker({
    showOtherMonths: true,
    selectOtherMonths: true,
    format: 'Y/m/d H:i',
    step: 1
  });
});
