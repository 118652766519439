/**
 * Flatpicker のデフォルトの設定
 */
export const DEFAULT_CONFIG_TABULATOR = {
  pagination: "local",
  locale: "ja",
  paginationSize: 10,
  layout: "fitColumns",
  paginationButtonCount: 3,
  placeholder: "データがありません。",
  paginationSizeSelector: [10, 20, 50, 100, true],
  pagination: false,
  langs: {
    ja: {
      //Japan language definition
      pagination: {
        page_size: "表示件数", //label for the page size select element
        page_title: "表示件数",
        first: "最初",
        first_title: "最初",
        last: "最後",
        last_title: "最後",
        prev: "前へ",
        prev_title: "前へ",
        next: "次へ",
        next_title: "次へ",
        all: "全て",
      },
    },
  },
};

export function GetImageWebPack(name) {
  try {
    return name ? require("../../../images/" + name) : null;
  } catch (error) {
    console.error(error);
  }
}

export function SetTabulatorRowPadding() {
  $(".tabulator-row").css("padding-left", $(".title-custom").width());
  $(".tabulator-headers").css("padding-left", $(".title-custom").width());
}

export const DisplayTextColor = (className) => {
  document.querySelectorAll(className).forEach((el) => {
    DecorateHtml(el);
  });
};

const DecorateHtml = (element) => {
  // let str =
  //   'test 青　[b:12vfvf] 赤　[r:12 ceer]　オレンジ[o:14 abc] test [BR:12 haha][br:12 haha2]';
  let str = element.innerText;
  const arrayRegex = str.match(/\[(B?|b?)[broBRO]:.*?\]/g);
  arrayRegex?.forEach((item) => {
    const contentItem = item.replace(/\[/, "").replace(/\]/, "");
    if (contentItem) {
      const value = contentItem.split(":");
      const className = getStyleClass(value[0]);
      const html = createHtml(className, value[1]);
      str = str.replace(item, html);
    }
  });
  element.classList.remove("d-flex");
  element.classList.add("d-block");
  element.innerHTML = str.replace(/\n/g, '<br>\n');
};

const getStyleClass = (key) => {
  switch (key.toLocaleLowerCase()) {
    case "b":
      return "text-primary";
    case "r":
      return "text-danger";
    case "o":
      return "text-warning";
    case "bb":
      return "text-primary text-bold";
    case "br":
      return "text-danger text-bold";
    case "bo":
      return "text-warning text-bold";
    default:
      return "";
  }
};

const createHtml = (className, value) => {
  return `<span class="${className}">${value}</span>`;
};

/**
 * Get filename from content disposition
 * @param {string} contentDisposition
 * @returns {string}
 */
export const getFileNameFromContentDisposition = (contentDisposition) => {
  const utf8FilenameRegex = /filename\*=UTF-8''([\w%\-\.]+)(?:; ?|$)/i;
  const asciiFilenameRegex = /filename="([^"]+)"|filename=([^;]+)/i;

  let fileName = null;
  if (utf8FilenameRegex.test(contentDisposition)) {
    fileName = decodeURIComponent(utf8FilenameRegex.exec(contentDisposition)[1]);
  } else {
    const matches = asciiFilenameRegex.exec(contentDisposition);
    if (matches != null) {
      fileName = matches[1] || matches[2];
    }
  }
  return fileName;
}