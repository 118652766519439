import { Base } from "../base";
import { Japanese } from "flatpickr/dist/l10n/ja.js";

import flatpickr from 'flatpickr';

import 'flatpickr/dist/flatpickr.min.css';

export class Admin__CouponHistories extends Base {
  /**
   * Action index
   */
  index() {
    // Datepicker
    console.log("🚀 ~ Run code javascript on Admin__CouponHistories index");
    document.getElementById('admin-container').addEventListener('scroll', function () {
      for (const calendar of document.getElementsByClassName('flatpickr-calendar')) {
        calendar.classList.remove('open');
      }
    });

    var datetimepicker = document.querySelectorAll('.datetimepicker');
    datetimepicker.forEach((element) => {
      flatpickr(element, {
        enableTime: true,
        dateFormat: 'Y/m/d H:i',
        locale: Japanese,
        defaultDate: element.value,
      });
      element.addEventListener('change', function (e) {
        element.value = dayjs(e.target.value).format('YYYY/MM/DD HH:mm');
      });
      element.addEventListener('keydown', function (e) {
        if (e.key === 'Backspace' || e.key === 'Delete') {
          e.target.value = '';
        }
      });
    });
  }
}
