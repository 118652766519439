export class Base {
  constructor(action) {
    if (typeof this["common"] === "function") {
      this["common"]();
    }
    if (typeof this[action] === "function") {
      this[action]();
    }

    // show/hide sub-menu when reload
    jQuery(function() {
      var animationSpeed = 300;
      if (this.location.href.includes('coupons/new')) {
        jQuery('.side-menu li.coupons').find('.coupon_add_new').addClass('active-menu');
      } else if (this.location.href.includes('coupons') && !this.location.href.includes('new')) {
        jQuery('.side-menu li.coupons').find('.coupon_list').addClass('active-menu');
      }
      // click parent menu
      jQuery('li.slide > span.parent_label').on('click', function() {
        var checkElement = jQuery(this).parent().find('ul.slide-menu');
        if (checkElement.css('display') === 'none') {
          checkElement.slideDown(animationSpeed, function() {
            checkElement.removeClass('hidden');
          });
          jQuery(this).parent().find('.angle.fa-angle-down').show();
          jQuery(this).parent().find('.angle.fa-angle-up').hide();
        } else if (checkElement.css('display') === 'block') {
          checkElement.slideUp(animationSpeed, function() {
            checkElement.addClass('hidden');
          });
          jQuery(this).parent().find('.angle.fa-angle-up').show()
          jQuery(this).parent().find('.angle.fa-angle-down').hide();
        }
      });
      // click menu
      jQuery('a.side-menu__item').on('click', function(e) {
        e.preventDefault();
        jQuery('div.loading-modal').show();
        location.href = jQuery(this).attr('href');
      });
      // click button has reload
      jQuery('.has-reload').on('click', function() {
        jQuery('div.loading-modal').show();
      });

      // show/hide menu on small screens
      jQuery('button.navbar-toggler').on('click', function() {
        if (jQuery('ul.side-menu').css('display') === 'none') {
          jQuery('i.fa-bars').addClass('hidden');
          jQuery('i.fa-xmark').removeClass('hidden');
          jQuery('aside.app-sidebar').css('height', '100vh');
          jQuery('ul.side-menu').slideDown();
        } else {
          jQuery('i.fa-bars').removeClass('hidden');
          jQuery('i.fa-xmark').addClass('hidden');
          jQuery('ul.side-menu').slideUp();
          jQuery('aside.app-sidebar').css('height', '50px');
        }
      });

    });
  }
}
